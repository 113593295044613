import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import indexRoutes from "./routes/";
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import  store from "./redux/store";
import { history } from "./jwt/_helpers";
import { PrivateRoute } from "./routes/PrivateRoutes";
import Blanklayout from "./layouts/blanklayout";
import PromotionRate from "./views/promotions/promotionRate";
import PromotionShare from "./views/promotions/promotionShare";
import Unsubscribe from "./views/unsubscribe/unsubscribe";

import ForgotPassword_step2 from "./views/ForgotPassword/ForgotPassword_step2";
import { StateContext } from "./utils/context";
import { getCookie } from "./jwt/_helpers/cookie";
import MusicLinkLandingPage from "./views/musicLink/landingPage";

// import { IntercomProvider } from 'react-use-intercom';
import CreateSubmittingForm from "./views/Landing/UnAuthSubmittingForm";
import BrokenLink from "./views/musicLink/brokenLink";
import TransactionSuccess from "./views/credits/transactionSuccess";
import TransactionFailure from "./views/credits/transactionFailure";

import { GoogleOAuthProvider } from '@react-oauth/google';
import LinkLandingPage from "./views/linkLandingPages/landingPage";
import BrokenBioLink from "./views/linkLandingPages/brokenBioLink";

import VerifyAccount from "./views/authentication/verifyAccount";
import SignUpSuccessfull from "./views/authentication/signUpSuccessfull";
import TenantProvider from "./utils/tenantProvider";
import ConsentModal from "./components/consentModal";
import IubendaScript from "./components/iubendaScript";

// const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

const GOOGLE_OAUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

const App = props => {
  const [currentUser, setCurrentUser] = useState({});
  const [token, setToken] = useState(getCookie('token'));

  const stateValue = { currentUser, setCurrentUser, token, setToken };

  const [isConsentModalOpen, setIsConsentModalOpen] = useState(false);
  
  useEffect(() => {
    if (currentUser && currentUser.consent_required) {
      setIsConsentModalOpen(true);
    }
  }, [currentUser]);

  Modal.prototype.componentWillUnmount = function() {
    if (this.props.onExit) {
      this.props.onExit();
    }
  
    if (this._element) {
      this.destroy();
      if (this.props.isOpen || this.state.isOpen) {
        this.close();
      }
    }
  
    this._isMounted = false;
  };

  return (
    <>
    {/* <IntercomProvider appId={INTERCOM_APP_ID}> */}
      <TenantProvider>
        <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
        <Provider store={store}>
          <StateContext.Provider value={stateValue}>
            <Router basename="/" history={history}>
              <Switch>
                <Route
                  path="/promotions/:promotionId/release-feedback/:recipientToken/"
                  exact={true}
                  component={PromotionRate}
                />
                <Route
                  path="/unsubscribe/:id/:token/"
                  exact={true}
                  component={Unsubscribe}
                />
                <Route
                  path="/promo-share/:token/"
                  exact={true}
                  component={PromotionShare}
                />
                <Route
                  path="/forgot-my-password/confirm/:token/"
                  exact={true}
                  component={ForgotPassword_step2}
                />
                {!token && (
                  <Route
                    path="/demo/:suffix?/:token?"
                    exact={true}
                    component={CreateSubmittingForm}
                  />
                )}
                <Route
                  path="/l/:urlpart1/:urlpart2"
                  exact={true}
                  component={MusicLinkLandingPage}
                />
                <Route
                  path="/l/:urlpart1/"
                  exact={true}
                  component={BrokenLink}
                />;
                <Route
                  path="/l/notFound/"
                  exact={true}
                  component={BrokenLink}
                />;
                <Route
                  path="/l/"
                  exact={true}
                  render={() => (window.location.assign('https://www.movemusic.io/'))}
                />;
                <Route
                  path="/b/:back_url/"
                  exact={true}
                  component={LinkLandingPage}
                />
                <Route
                  path="/bioNotFound/"
                  exact={true}
                  component={BrokenBioLink}
                />;
                <Route
                  path="/b/"
                  exact={true}
                  render={() => (window.location.assign('https://www.movemusic.io/'))}
                />;
                <Route path="/authentication/login" component={Blanklayout} />;
                <Route path="/authentication/signup" component={Blanklayout} />;
                <Route path="/authentication/signup-successfull" exact={true} component={SignUpSuccessfull} />;
                <Route path="/verify/:token" exact={true} component={VerifyAccount}/>;
                <Route path="/credits/order/transactionSuccessfull" exact={true} component={TransactionSuccess}/>;
                <Route path="/credits/order/transactionCancelled" exact={true} component={TransactionFailure}/>;
                {indexRoutes.map((prop, key) => {
                  return (
                    <PrivateRoute
                      path={prop.path}
                      key={key}
                      component={prop.component}
                    />
                  );
                })}
              </Switch>
            </Router>
          </StateContext.Provider>
        </Provider>
        </GoogleOAuthProvider>
      </TenantProvider>
      <ConsentModal 
        isConsentModalOpen={isConsentModalOpen} 
        toggle={() => setIsConsentModalOpen(!isConsentModalOpen)} 
        setIsConsentModalOpen={setIsConsentModalOpen}
        currentUser={currentUser}
      />
      <IubendaScript currentUser={currentUser} />
      {/* </IntercomProvider> */}
    </>
  );
};

export default App;
