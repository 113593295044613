import { useEffect } from 'react';

const IUBENDA_API_KEY_MMD_DE = process.env.REACT_APP_IUBENDA_API_KEY_MMD_DE;
const IUBENDA_API_KEY_MMD_HR = process.env.REACT_APP_IUBENDA_API_KEY_MMD_HR;

const IubendaScript = ({ currentUser }) => {
  useEffect(() => {
    if(currentUser && currentUser.contract_owner) {
        let apiKey = null;
        let contractOwner = currentUser.contract_owner;
        if (contractOwner === 1) {
            apiKey = IUBENDA_API_KEY_MMD_DE;
        } else if (contractOwner === 2) {
            apiKey = IUBENDA_API_KEY_MMD_HR;
        }
        if (apiKey) {
            const scriptInit = document.createElement('script');
            scriptInit.type = 'text/javascript';
            scriptInit.innerHTML = `
                _iub.cons_instructions.push(["init", {
                    api_key: "${apiKey}",
                    logger: "console",
                    log_level: "warn",
                    function() {
                        console.log("init Callback");
                    },
                }]);
            `;
            document.head.appendChild(scriptInit);
        }
    }
  }, [currentUser]);

  return null;
};

export default IubendaScript;